import { Redirect, Switch } from 'react-router-dom'
import store from 'meta/store'
import isRedirect from 'routes/isRedirect'
import AsyncComponent from 'components/meta/Async'
import Path from './Path'
import NotFound from 'views/core/NotFound'
import ErrorView from 'views/core/Error'
import {
  checkPlace,
  checkAccountState,
  checkLoggedIn,
  checkLoggedOut,
  checkPlaceAdmin,
  checkPlaceMember,
  checkSuperAdmin,
  checkUnverified,
  compose
} from './redirects'

// Async chunks don't get nice names by default. Supply them via comment
// See https://webpack.js.org/api/module-methods/#import-

// core
const Login = AsyncComponent(() => import(/* webpackChunkName: "views/core/Login" */ 'views/core/Login'))
const SignUp = AsyncComponent(() => import(/* webpackChunkName: "views/core/SignUp" */ 'views/core/SignUp'))
const Confirmation = AsyncComponent(() => import(/* webpackChunkName: "views/core/Confirmation" */ 'views/core/Confirmation'))
const ForgotPassword = AsyncComponent(() => import(/* webpackChunkName: "views/core/ForgotPassword" */ 'views/core/ForgotPassword'))
const ResetPassword = AsyncComponent(() => import(/* webpackChunkName: "views/core/ResetPassword" */ 'views/core/ResetPassword'))
const Explore = AsyncComponent(() => import(/* webpackChunkName: "views/core/Explore" */ 'views/core/Explore'))
const APIDocumentation = AsyncComponent(() => import(/* webpackChunkName: "views/core/APIDocumentation" */ 'views/core/APIDocumentation'))
const Logout = AsyncComponent(() => import(/* webpackChunkName: "views/core/Logout" */ 'views/core/Logout'))
const Admin = AsyncComponent(() => import(/* webpackChunkName: "views/core/Admin" */ 'views/core/Admin'))
const RolesAndOperationsList = AsyncComponent(() => import(/* webpackChunkName: "views/core/RolesAndOperations/List" */ 'views/core/RolesAndOperations/List'))
const RolesAndOperationsDetail = AsyncComponent(() => import(/* webpackChunkName: "views/core/RolesAndOperations/Detail" */ 'views/core/RolesAndOperations/Detail'))
const RolesAndOperationsEdit = AsyncComponent(() => import(/* webpackChunkName: "views/core/RolesAndOperations/Edit" */ 'views/core/RolesAndOperations/Edit'))

// user
const Invite = AsyncComponent(() => import(/* webpackChunkName: "views/user/Invite" */ 'views/user/Invite'))
const MySettings = AsyncComponent(() => import(/* webpackChunkName: "views/user/Settings" */ 'views/user/Settings'))
const CreateSource = AsyncComponent(() => import(/* webpackChunkName: "views/user/Sources/Create" */ 'views/user/Sources/Create'))
const SourceDetails = AsyncComponent(() => import(/* webpackChunkName: "views/user/Sources/Details" */ 'views/user/Sources/Details'))
const ExportSource = AsyncComponent(() => import(/* webpackChunkName: "views/user/Sources/Export" */ 'views/user/Sources/Export'))
const DataTypeCreator = AsyncComponent(() => import(/* webpackChunkName: "views/user/DataTypes/Create" */ 'views/user/DataTypes/Create'))
const DataTypeEditor = AsyncComponent(() => import(/* webpackChunkName: "views/user/DataTypes/Edit" */ 'views/user/DataTypes/Edit'))
const CreateInsight = AsyncComponent(() => import(/* webpackChunkName: "views/user/Insights/Create" */ 'views/user/Insights/Create'))
const ModifyInsight = AsyncComponent(() => import(/* webpackChunkName: "views/user/Insights/Edit" */ 'views/user/Insights/Edit'))
const StandaloneInsight = AsyncComponent(() => import(/* webpackChunkName: "views/user/Insights/Standalone" */ 'views/user/Insights/Standalone'))
const InsightReference = AsyncComponent(() => import(/* webpackChunkName: "views/core/Insights/Reference" */ 'views/core/Insights/Reference'))

const CreateImporter = AsyncComponent(() => import(/* webpackChunkName: "views/user/Importers/Create" */ 'views/user/Importers/Create'))
const ModifyImporter = AsyncComponent(() => import(/* webpackChunkName: "views/user/Importers/Edit" */ 'views/user/Importers/Edit'))
const ImporterJobs = AsyncComponent(() => import(/* webpackChunkName: "views/user/Importers/Jobs" */ 'views/user/Importers/Details'))

// place
const DataTypeList = AsyncComponent(() => import(/* webpackChunkName: "views/core/DataTypes/List" */ 'views/core/DataTypes/List'))
const DataTypeDetails = AsyncComponent(() => import(/* webpackChunkName: "views/core/DataTypes/Details" */ 'views/core/DataTypes/Details'))
const PlaceObserverList = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Observers/List" */ 'views/place/ForType/Observers/List'))
const PlaceObserverDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Observers/Details" */ 'views/place/ForType/Observers/Details'))
const PlaceObserverCreate = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Observers/Create" */ 'views/place/ForType/Observers/Create'))
const PlaceObserverEdit = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Observers/Edit" */ 'views/place/ForType/Observers/Edit'))
const PlaceSources = AsyncComponent(() => import(/* webpackChunkName: "views/place/Sources" */ 'views/place/Sources'))
const PlaceExport = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Export" */ 'views/place/ForType/Export'))
const PlaceTeam = AsyncComponent(() => import(/* webpackChunkName: "views/place/Team" */ 'views/place/Team'))
const PlaceManageSettings = AsyncComponent(() => import(/* webpackChunkName: "views/place/Manage" */ 'views/place/Manage'))
const PlaceManagePublicHub = AsyncComponent(() => import(/* webpackChunkName: "views/place/Manage/PublicHub" */ 'views/place/Manage/PublicHub'))
const PlaceUsage = AsyncComponent(() => import(/* webpackChunkName: "views/place/Usage" */ 'views/place/Usage'))
const PlaceMap = AsyncComponent(() => import(/* webpackChunkName: "views/place/Map" */ 'views/place/Map'))
const PlaceAPIDocumentation = AsyncComponent(() => import(/* webpackChunkName: "views/place/APIDocumentation" */ 'views/place/APIDocumentation'))
const Onboarding = AsyncComponent(() => import(/* webpackChunkName: "views/place/Onboarding" */ 'views/place/Onboarding'))
const Activity = AsyncComponent(() => import(/* webpackChunkName: "views/place/Activity" */ 'views/place/Activity'))
const Home = AsyncComponent(() => import(/* webpackChunkName: "views/place/Home" */ 'views/place/Home'))
const PlaceCollectionList = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/List" */ 'views/place/Collections/List'))
const PlaceCollectionDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Details" */ 'views/place/Collections/Details'))
const PlaceCollectionEdit = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Edit" */ 'views/place/Collections/Edit'))
const PlaceCollectionCreate = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Create" */ 'views/place/Collections/Create'))
const PlaceInsightInventory = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Inventory" */ 'views/place/Collections/Inventory'))
const PlaceCollectionStandalone = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Standalone" */ 'views/place/Collections/Standalone'))
const PlacePageList = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/List" */ 'views/place/Pages/List'))
const PlacePageBlankDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Blank/Details" */ 'views/place/Pages/Blank/Details'))
const PlacePageBlankEdit = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Blank/Edit" */ 'views/place/Pages/Blank/Edit'))
const PlacePageBlankPreview = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Blank/Preview" */ 'views/place/Pages/Blank/Preview'))

// public dashboard page
const PlacePageDashboardDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Dashboard/Details" */ 'views/place/Pages/Dashboard/Details'))
const PlacePageDashboardEdit = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Dashboard/Edit" */ 'views/place/Pages/Dashboard/Edit'))
const PlacePageDashboardPreview = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Dashboard/Preview" */ 'views/place/Pages/Dashboard/Preview'))

// public storytelling page
const PlacePageStoryTellingDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/StoryTelling/Details" */ 'views/place/Pages/StoryTelling/Details'))
const PlacePageStoryTellingEdit = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/StoryTelling/Edit" */ 'views/place/Pages/StoryTelling/Edit'))
const PlacePageStoryTellingPreview = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/StoryTelling/Preview" */ 'views/place/Pages/StoryTelling/Preview'))


const MediaLibrary = AsyncComponent(() => import(/* webpackChunkName: "views/place/MediaLibrary" */ 'views/place/MediaLibrary'))

// exporting one-offs
const Tableau = AsyncComponent(() => import(/* webpackChunkName: "views/core/Tableau" */ 'views/core/Tableau'))
const PowerBI = AsyncComponent(() => import(/* webpackChunkName: "views/core/PowerBI" */ 'views/core/PowerBI'))
const Esri = AsyncComponent(() => import(/* webpackChunkName: "views/core/Esri" */ 'views/core/Esri'))
const GoogleSheets = AsyncComponent(() => import(/* webpackChunkName: "views/core/GoogleSheets" */ 'views/core/GoogleSheets'))

const isLoggedInUnverified = compose(checkLoggedIn, checkUnverified)
const isLoggedInSuperAdmin = compose(checkLoggedIn, checkSuperAdmin)
const isLoggedInMuniMember = compose(checkLoggedIn, checkPlaceMember)
const isLoggedInMuniAdmin = compose(checkLoggedIn, checkPlaceAdmin)
const isLoggedInPlain = () => !!store.getState().me

// define some more specific compositions
const RootPath = (props) => {
  const MaybeAuthRedirect = checkAccountState(props)
  if (isRedirect(MaybeAuthRedirect)) return MaybeAuthRedirect
  if (!MaybeAuthRedirect) return <Redirect to="/explore" {...props} />

  // check if they have a muni
  const MaybeMuniRedirect = checkPlace(props)
  if (isRedirect(MaybeMuniRedirect)) return MaybeMuniRedirect
  return <Redirect to="/home" {...props} />
}

export default <Switch>
  <Path exact path="/" component={RootPath} />
  <Path exact path="/export/tableau" component={Tableau} />
  <Path exact path="/export/powerbi" component={PowerBI} />
  <Path exact path="/export/esri" component={Esri} />
  <Path exact path="/export/google_sheets" component={GoogleSheets} />
  <Path exact navigation={isLoggedInPlain} path="/explore" component={Explore} check={checkAccountState} />
  <Path navigation exact path="/core_api" component={APIDocumentation} check={checkAccountState} />
  <Path navigation exact path="/account" component={MySettings} check={checkLoggedIn} />
  <Path navigation exact path="/admin" component={Admin} check={isLoggedInSuperAdmin} />
  <Path navigation exact path="/hello" component={Onboarding} check={checkLoggedIn} />
  <Path navigation exact path="/home" component={Home} check={isLoggedInMuniMember} />
  <Path navigation exact path="/activity" component={Activity} check={isLoggedInMuniMember} />

  <Path navigation exact path="/map" component={PlaceMap} check={isLoggedInMuniMember} />

  <Path navigation exact path="/collections" component={PlaceCollectionList} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/create" component={PlaceCollectionCreate} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/inventory" component={PlaceInsightInventory} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/:collectionId" component={PlaceCollectionDetails} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/:collectionId/modify" component={PlaceCollectionEdit} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/:collectionId/insights/:insightId" component={PlaceCollectionStandalone} check={isLoggedInMuniMember} />
  <Path navigation exact path="/collections/:collectionId/text/:textId" component={PlaceCollectionStandalone} check={isLoggedInMuniMember} />

  <Path navigation exact path="/pages" component={PlacePageList} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/blank/:pageId" component={PlacePageBlankDetails} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/blank/:pageId/modify" component={PlacePageBlankEdit} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/blank/:pageId/preview" component={PlacePageBlankPreview} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/dashboard/:pageId" component={PlacePageDashboardDetails} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/dashboard/:pageId/modify" component={PlacePageDashboardEdit} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/dashboard/:pageId/preview" component={PlacePageDashboardPreview} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/storytelling/:pageId" component={PlacePageStoryTellingDetails} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/storytelling/:pageId/modify" component={PlacePageStoryTellingEdit} check={isLoggedInMuniMember} />
  <Path navigation exact path="/pages/storytelling/:pageId/preview" component={PlacePageStoryTellingPreview} check={isLoggedInMuniMember} />

  <Path navigation exact path="/types/create" component={DataTypeCreator} check={checkLoggedIn} />
  <Path navigation exact path="/types/catalog" component={DataTypeList} />
  <Path navigation exact path="/types/:dataTypeId" component={DataTypeDetails} />
  <Path navigation exact path="/types/:dataTypeId/observers" component={PlaceObserverList} check={isLoggedInMuniMember} />
  <Path navigation exact path="/types/:dataTypeId/observers/create" component={PlaceObserverCreate} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/types/:dataTypeId/observers/:observerId" component={PlaceObserverDetails} check={isLoggedInMuniMember} />
  <Path navigation exact path="/types/:dataTypeId/observers/:observerId/modify" component={PlaceObserverEdit} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/types/:dataTypeId/export" component={PlaceExport} check={checkPlace} />
  <Path navigation exact path="/types/:dataTypeId/modify" component={DataTypeEditor} check={checkLoggedIn} />
  <Path navigation exact path="/insights/create" component={CreateInsight} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/insights/reference" component={InsightReference} />
  <Path navigation exact path="/insights/:insightId/modify" component={ModifyInsight} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/insights/:insightId" component={StandaloneInsight} check={isLoggedInMuniMember} />
  <Path navigation exact path="/api" component={PlaceAPIDocumentation} check={checkPlace} />
  <Path navigation exact path="/team" component={PlaceTeam} check={isLoggedInMuniMember} />
  <Path navigation exact path="/org/manage" component={PlaceManageSettings} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/org/manage/hub" component={PlaceManagePublicHub} check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/hub/usage" component={PlaceUsage} check={isLoggedInMuniAdmin} />

  <Path navigation exact path="/sources" component={PlaceSources} check={checkLoggedIn} />
  <Path navigation exact path="/sources/create" component={CreateSource} check={checkLoggedIn} />
  <Path
    navigation exact path="/sources/community" component={PlaceSources} isCommunity
    check={isLoggedInMuniAdmin} />
  <Path navigation exact path="/sources/:sourceId" component={SourceDetails} />
  <Path navigation exact path="/sources/:sourceId/export" component={ExportSource} />
  <Path navigation exact path="/sources/:sourceId/importers/create" component={CreateImporter} check={checkLoggedIn} />
  <Path navigation exact path="/sources/:sourceId/importers/:importerId" component={ImporterJobs} check={checkLoggedIn} />
  <Path navigation exact path="/sources/:sourceId/importers/:importerId/modify" component={ModifyImporter} check={checkLoggedIn} />
  <Path navigation exact path="/sources/:sourceId/importers/:importerId/:jobId" component={ImporterJobs} check={checkLoggedIn} />

  <Path navigation exact path="/media-library" component={MediaLibrary} check={isLoggedInMuniMember} />

  <Path exact path="/login" component={Login} check={checkLoggedOut} />
  <Path exact path="/logout" component={Logout} check={isLoggedInPlain} />
  <Path exact path="/signup" component={SignUp} check={checkLoggedOut} />
  <Path exact path="/invite/:inviteId" component={Invite} check={checkLoggedIn} />
  <Path exact path="/forgot_password" component={ForgotPassword} check={checkLoggedOut} />
  <Path exact path="/reset_password" component={ResetPassword} check={checkLoggedOut} />
  <Path exact path="/confirmation" component={Confirmation} check={isLoggedInUnverified} />
  <Path navigation exact path="/rolesOperations" component={RolesAndOperationsList} check={isLoggedInSuperAdmin} />
  <Path navigation exact path="/rolesOperations/:roleId" component={RolesAndOperationsDetail} check={isLoggedInSuperAdmin} />
  <Path navigation exact path="/rolesOperations/:roleId/modify" component={RolesAndOperationsEdit} check={isLoggedInSuperAdmin} />
  <Path navigation exact path="/rolesOperations/role/create" component={RolesAndOperationsEdit} check={isLoggedInSuperAdmin} />

  <Path exact path="/error" component={ErrorView} />
  <Path component={NotFound} />
</Switch>
