import { Component } from 'react'
import context from 'meta/context'
import cn from 'classnames'
import PropTypes from 'meta/PropTypes'
import { Layout, Flex, Box } from 'react-flex-lite'
import { isHub } from 'lib/hub'
import { Medium } from 'components/display/Text'
import moment from 'moment-timezone'
import LinkButton from 'components/interactive/LinkButton'
import { MdSearch, MdOutlineTranslate, MdOutlineInvertColors, MdOutlineBrightness6 } from 'components/display/Icons'
import { ThemeContext } from 'components/display/ThemeProvider'
import './index.scss'

@context([ 'place' ])
@Layout
export default class HubHeader extends Component {
  static propTypes = {
    place: PropTypes.place.isRequired
  }

  static defaultProps = {}

  static contextType = ThemeContext


  render = () => {
    const { theme, toggleTheme } = this.context
    const { place } = this.props

    if (!isHub()) return null
    return <Flex
      className={cn('hub-top-header-component', { dark: theme === 'dark', light: theme === 'light' })}
      justify="space-between"
      px={3}
      py={2}>
      <Flex className="left-container" align="center">
        <Box className="indicators-count" mr={3}>
          <Medium className="label">INDICATORS</Medium>
          <Medium className="value">1,768</Medium>
        </Box>
        <Box className="updated-date">
          <Medium className="label">UPDATED</Medium>
          <Medium className="value">
            {moment.tz(place.timeZone).format('MMM DD, YYYY | hh:mm A')}
          </Medium>
        </Box>
      </Flex>
      <Flex className="right-container" align="center">
        <LinkButton className="search" plain mr={3} Icon={MdSearch}>
          Search
        </LinkButton>
        <LinkButton className="translate" plain mr={3} Icon={MdOutlineTranslate}>
          Translate
        </LinkButton>
        {theme === 'dark-theme' ?
          <MdOutlineBrightness6
            className="theme-icon"
            onClick={toggleTheme} />
          :
          <MdOutlineInvertColors
            className="theme-icon"
            onClick={toggleTheme} />
        }
      </Flex>
    </Flex>
  }
}
