import { PureComponent } from 'react'
import { Flex } from 'react-flex-lite'
import context from 'meta/context'
import PropTypes from 'meta/PropTypes'
import PageClick from 'react-page-click'
import { Link } from 'react-router-dom'
import logoUrl from 'assets/logo-white.svg'
import { Medium } from 'components/display/Text'
import { isSuperAdmin } from 'lib/getUserRole'
import { MdCancel, MdMoreVert, MdExtChevronLeftCircleOutline, MdExtChevronRightCircleOutline } from 'components/display/Icons'
import ErrorMessage from 'components/display/Error'
import LinkButton from 'components/interactive/LinkButton'
import WorkspaceSwitcher from 'components/interactive/WorkspaceSwitcher'
import cn from 'classnames'
import './index.scss'
import { isHub } from 'lib/hub'
import logoUrlHub from 'assets/clientLogo.png'
import { ThemeContext } from 'components/display/ThemeProvider'

const linkList = PropTypes.arrayOf(PropTypes.shape({
  visible: PropTypes.oneOfType([
    PropTypes.func, // test to show or not
    PropTypes.bool
  ]),
  path: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string, // link title
  text: PropTypes.string,
  Icon: PropTypes.func, // icon function
  to: PropTypes.string, // url to go to
  external: PropTypes.bool, // is this a SPA route or an external page?
  onClick: PropTypes.func // additional stuff to run on click
}))

const activeAliases = {
  sources: [ 'types' ],
  collections: [ 'insights' ]
}

const getActiveLink = (props) => {
  if (!props.path) return false
  const root = props.path.split('/')[1]
  const to = props.to.replace('/', '')
  if (activeAliases[to]?.includes(root)) return true
  return false
}

const SideBarLink = (link) => <LinkButton
  plain
  column center
  external={link.external}
  active={getActiveLink(link)}
  to={link.to}
  onClick={link.onClick}
  Icon={link.Icon}
  title={link.title}
  exact={link.exact || false}
  className={cn('sidebar-link', link.className)}>
  {link.text ? <div className="link-text">{link.text}</div> : null}
</LinkButton>
@context([ 'me', 'place' ])
export default class SideBar extends PureComponent {
  static propTypes = {
    me: PropTypes.user,
    place: PropTypes.place,
    logo: PropTypes.bool,
    topLinks: linkList,
    bottomLinks: linkList,
    path: PropTypes.string,
    children: PropTypes.node,
    showCollapse: PropTypes.bool
  }
  state = {
    error: null,
    activeMenu: null,
    dismissed: false,
    collapse: false
  }
  componentDidCatch = (error, errorInfo) => {
    console.error('SideBar caught error!', error, errorInfo)
    this.setState({ error, errorInfo })
  }
  static contextType = ThemeContext
  filterLink = (l) => typeof l.visible === 'function'
    ? l.visible(this.props.me, this.props.place)
    : l.visible !== false
  handleDismiss = () => {
    this.setState({ dismissed: true })
  }
  closeMenus = () => {
    this.setState({ activeMenu: null })
  }
  toggleMenu = (name) => {
    this.setState((s) => ({ activeMenu: s.activeMenu === name ? null : name }))
  }
  toggleBottomMenu = () => {
    this.toggleMenu('bottom')
  }
  toggleWorkspaceMenu = () => {
    this.toggleMenu('workspace')
  }
  toggleCollapse = () => {
    this.setState((prev) => ({
      collapse: !prev.collapse
    }))
  }
  onBottomClick = (link) => {
    const { onClick } = link
    link.onClick = (e) => {
      this.closeMenus()
      if (onClick) return onClick(e)
    }
    return link
  }
  renderPlaceSwitcher = () =>
    <WorkspaceSwitcher
      active={this.state.activeMenu === 'workspace'}
      onActivate={this.toggleWorkspaceMenu}
      onClose={this.closeMenus} />

  renderBottomLinks = () => {
    if (this.props.bottomLinks.length === 0) return null
    if (this.props.bottomLinks.filter(this.filterLink).length === 1) {
      return <Flex auto column justify="flex-end" className="bottom-links">
        {this.renderPlaceSwitcher()}
        {this.renderLinks(this.props.bottomLinks)}
      </Flex>
    }
    return <PageClick notify={this.closeMenus}>
      <Flex auto column justify="flex-end" className="bottom-links">
        {this.renderPlaceSwitcher()}
        {this.state.activeMenu === 'bottom' &&
          <Flex className="sidebar-menu">
            {this.renderLinks(this.props.bottomLinks.map(this.onBottomClick))}
          </Flex>
        }
        <SideBarLink
          Icon={MdMoreVert}
          onClick={this.toggleBottomMenu} />
      </Flex>
    </PageClick>
  }
  getNav = () => {
    const { theme } = this.context

    const logoLink = <Link to="/" title="Home" className="sidebar-logo">
      {isHub() ? <img src={logoUrlHub} className="responsive-logo-hub" /> : <img src={logoUrl} className="responsive-logo" />}
    </Link>

    return <Flex
      column justify="space-between"
      className={cn('sidebar-content', { hub: isHub(), collapse: this.state.collapse }, theme)} >
      {this.props.logo && logoLink}
      {this.props.topLinks &&
      <Flex auto column justify="flex-start" className="top-links">
        {isHub() && <div className="collapse-icon" onClick={() => this.toggleCollapse()}>{!this.state.collapse ? <MdExtChevronLeftCircleOutline /> : <MdExtChevronRightCircleOutline />}</div>}
        {this.renderLinks(this.props.topLinks)}
      </Flex>}
      {this.renderBottomLinks()}
    </Flex>
  }
  renderLinks = (links) => links.filter(this.filterLink).map(this.renderLink)
  renderLink = (link, idx) => {
    // clear the error state to give the next route a chance to render
    const onClick = this.state.error
      ? (...args) => this.setState({ error: null }) || link.onClick && link.onClick(...args)
      : link.onClick
    return <SideBarLink {...link} onClick={onClick} key={idx} path={this.props.path} />
  }

  render = () => {
    const isSuper = isSuperAdmin(this.props.me)
    const cautionSuperAdmin = <Flex
      p={1} align="center"
      justify="space-between"
      className="caution-superadmin-banner">
      <Medium weight="medium">
        You are on a superadmin account - be careful!
      </Medium>
      <MdCancel ml={2} size={24} className="cancel-icon" onClick={this.handleDismiss} />
    </Flex>
    return <Flex className="sidebar-component">
      {this.getNav()}
      <div className="sidebar-child-content">
        {this.state.error
          ? <Flex auto center style={{ height: '100%' }}>
            <ErrorMessage error={this.state.error} errorInfo={this.state.errorInfo} />
          </Flex>
          : this.props.children
        }
        {isSuper && !isHub() && !this.state.dismissed && cautionSuperAdmin}
      </div>
    </Flex>
  }
}
