import { PureComponent, createContext } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { isHub } from 'lib/hub'
import { Layout } from 'react-flex-lite'
import './index.scss'

export const ThemeContext = createContext({
  theme: '',
  toggleTheme: () => {}
})

@Layout
export default class ThemeProvider extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  state = {
    theme: isHub() ? localStorage.getItem('theme') : ''
  }

  toggleTheme = () => {
    const { theme } = this.state
    if (!isHub()) return
    const newTheme = !theme ? 'dark-theme' : ''
    this.setState({ theme: newTheme }, () => {
      if (newTheme) {
        localStorage.setItem('theme', newTheme)
      } else {
        localStorage.removeItem('theme')
      }
    })
  };

  render() {
    const { theme } = this.state
    const { children, className } = this.props

    return (
      <ThemeContext.Provider className={cn('theme-provider', className)} value={{ theme, toggleTheme: this.toggleTheme }}>
        {children}
      </ThemeContext.Provider>
    )
  }
}
